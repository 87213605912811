import './OurTeam.css';

import Footer from '../Footer/Footer';
import MainSection from '../Home/MainSection';
import P_1 from './bg.jpg';
import React from 'react';
import bgs from './bg.jpg';

function OurTeam() {
  return (
    <div>
    {/*}
    <section className="Team-section">
    <div className="Team-overlay"></div>
    <div className="Team-head-sec">
      <h1 className="Team-title">Meet the Passionate Minds Behind SRCV Services</h1>
      <button className="Home-button">Join Our Workshops</button>
    </div>
  </section>
  */}
  <MainSection
  backgroundImage={bgs}
  titleText="Meet the Passionate Minds Behind SRCV Services"
/>


  <div className="container">
      <div className="card_team">
        <div className="image-placeholder">
        <img src={P_1} alt='person-1'></img>
        </div>
        <div className="content">
          <h2 className="title">Founder & Executive Director</h2>
          <h3 className="name">Dr. Sohni Singh</h3>
          <p className="description">
            Dr. Sohni Singh is a visionary leader and advocate for equality and diversity. With her background in public service and her active role on the People's Advisory Panel for Wyndham City Council, Dr. Singh brings a wealth of knowledge and experience to SRCV Services. Her commitment to empowering communities shapes our programs and initiatives, ensuring they resonate with the needs of those we serve.
          </p>
        </div>
      </div>
      
      <div className="card_team">
        <div className="content">
          <h2 className="title">Professor</h2>
          <h3 className="name">Prof Dinesh Kumar</h3>
          <p className="description">
            Professor Dinesh Kant Kumar works at RMIT University for the last 26 years. He is a fellow of the Australian Institute of Digital Health. He is also on the Therapeutic Drugs Advisory Panel for medical devices. Dinesh is passionate about the education and development of students and has written two books on the topics.
          </p>
        </div>
        <div className="image-placeholder">
        <img src={P_1} alt='pers0n-1'></img>
        </div>
      </div>
    </div>
<Footer></Footer>
    </div>
  )
}

export default OurTeam
