import './Services.css'

import Footer from '../Footer/Footer';
import MainSection from '../Home/MainSection';
import React from 'react'
import cm_1 from './images/cm_1.png';
import cm_2 from './images/cm_2.png';
import cm_3 from './images/cm_3.png';
import cm_home from './images/community_bg.png';
import div_1 from './images/diversity_1.png';
import div_2 from './images/diversity_2.png';
import div_3 from './images/diversity_3.png';
import div_home from './images/di_home.png';
import sc_1 from './images/sc_1.jpg';
import sc_one from './images/sc_1.1.jpg';
import sc_three from './images/sc_1.2.jpg';
import sc_two from './images/sc_1.3.jpg';
import service_bg from './images/bg.jpg';

function Services() {
  return (
    <div>
    
    {/*<section className="Home-section">
    <div className="Home-overlay"></div>
    <div className="Home-head-sec">
      <h1 className="Home-title">Tailored Community Services for Lasting Impact</h1>
      <button className="Home-button">Join Our Workshops</button>
    </div>
  </section>
  */}
  
  <MainSection
        backgroundImage={service_bg}
        titleText="Tailored Community Services for Lasting Impact"
      />



  <section className="education-section">
  <div className="education-header">
    <h1 className="education-title">Education Support for Parents and Kids</h1>
    <p className="education-subtitle">
      Tailored mentoring and guidance for parents and students to make informed educational choices.
    </p>
    <a href="#" className="know-more-link">Know more &gt;</a>
  </div>

  <div className="education-image">
    <img src={sc_1} alt="Education Support" />
  </div>
</section>

{/* Cards Section */}
<section className="services-section">
  <h2 className="services-title">Education Support for Parents and Kids</h2>
  <p className="services-description">
    Personalized sessions guide parents and students in making informed education choices and post-school plans, using data-driven insights aligned with individual goals, family context, and aspirations.
  </p>

  <div className="services-cards">
    <div className="service-card">
      <img src={sc_one} alt="Consultation" className="service-card-image" />
      <h3 className="service-card-title">Student and Parents Consultation</h3>
    </div>
    <div className="service-card">
      <img src={sc_three} alt="Strategy" className="service-card-image" />
      <h3 className="service-card-title">Strategy Development</h3>
    </div>
    <div className="service-card">
      <img src={sc_two} alt="Framework" className="service-card-image" />
      <h3 className="service-card-title">Education Framework</h3>
    </div>
  </div>
</section>

<section className="education-section">
  <div className="education-header">
    <h1 className="education-title">Community leadership</h1>
    <p className="education-subtitle">
    Workshops and programs to empower individuals to lead and make a positive impact in their communities    </p>
    <a href="#" className="know-more-link">Know more &gt;</a>
  </div>

  <div className="education-image">
    <img src={cm_home} alt="Education Support" />
  </div>
</section>

{/* Cards Section */}
<section className="services-section">
  <h2 className="services-title">Community leadership</h2>
  <p className="services-description">
  Community leadership involves making a tangible difference in the lives of community members through
  dedication, empathy, and effective collaboration.  </p>

  <div className="services-cards">
    <div className="service-card">
      <img src={cm_1} alt="Consultation" className="service-card-image" />
      <h3 className="service-card-title">Self Awareness and Advocacy for migrant Women</h3>
    </div>
    <div className="service-card">
      <img src={cm_2} alt="Strategy" className="service-card-image" />
      <h3 className="service-card-title">Professional workshops for new migrants</h3>
    </div>
    <div className="service-card">
      <img src={cm_3} alt="Framework" className="service-card-image" />
      <h3 className="service-card-title">Professional workshops for new migrants</h3>
    </div>
  </div>
</section>
<section className="education-section">
  <div className="education-header">
    <h1 className="education-title">Diversity in STEM</h1>
    <p className="education-subtitle">
    Initiatives and programs to inspire and support diverse participation in STEM careers.
    </p>
    <a href="#" className="know-more-link">Know more &gt;</a>
  </div>

  <div className="education-image">
    <img src={div_home} alt="Education Support" />
  </div>
</section>

{/* Cards Section */}
<section className="services-section">
  <h2 className="services-title">Community leadership</h2>
  <p className="services-description">
  Community leadership involves making a tangible difference in the lives of community members through
  dedication, empathy, and effective collaboration.
  </p>

  <div className="services-cards">
    <div className="service-card">
      <img src={div_1} alt="Consultation" className="service-card-image" />
      <h3 className="service-card-title">STEM programs boosting female participation.</h3>
    </div>
    <div className="service-card">
      <img src={div_2} alt="Strategy" className="service-card-image" />
      <h3 className="service-card-title">Fostering networking and collaboration.</h3>
    </div>
    <div className="service-card">
      <img src={div_3} alt="Framework" className="service-card-image" />
      <h3 className="service-card-title">STEM community support and networking.</h3>
    </div>
  </div>
</section>
<Footer></Footer>
    </div>
  )
}

export default Services
