import './About.css';

import Cards from './Cards';
import Footer from '../Footer/Footer';
import MainSection from '../Home/MainSection';
import React from 'react'
import abou_bg from './Images/update_bg.jpg';

function Abou() {
  return (
    <div>
    {/*
    <section className="About-section">
    <div className="About-overlay"></div>
    <div className="About-head-sec">
      <h1 className="About-title">Tailored Community Services for Lasting Impact</h1>
      <button className="About-button">Join Our Workshops</button>
    </div>
  </section>
  */}


  <MainSection
        backgroundImage={abou_bg}
        titleText="Empowering Communities, Creating Lasting Impact
"
      />
      
  <Cards></Cards>
  <Footer></Footer>
    </div>
  )
}

export default Abou
