import './Feed.css';

import React, { useState } from 'react';

const FeedbackForm = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  return (
    <div>
      {/* Feedback Icon */}
      <div
        className="feedback-button"
        onClick={handleToggleForm}
      >
        <span role="img" aria-label="feedback">💬</span>
      </div>

      {/* Google Form iframe Container */}
      {isFormOpen && (
        <div className="form-overlay">
          <div className="form-container">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSeJ1TnQoS9IInVBATTQA7d6-vqDTs_fEBNTr2JeY7kT9PpqXQ/viewform?embedded=true"
              width="100%"
              height="600px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="google-form"
              className="form-content"
              allowFullScreen
            >
              Loading…
            </iframe>
            <button className="form-close-button" onClick={handleToggleForm}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
