import './Home.css'; // Add this CSS to your App.css file

import Footer from '../Footer/Footer';
import HomeBg from './Images/Home_bg.jpg';
import Home_bg from './Images/Home_bg.jpg';
import MainSection from './MainSection';
import Main_Nav from '../Navbar/Main_Nav';
import React from 'react';
import icon_1 from './Images/fram1.png';
import icon_2 from './Images/frame2.png';
import icon_3 from './Images/frame3.png';
import srcv_1 from './Images/service-1.png';
import srcv_2 from './Images/service-2.png';
import srcv_3 from './Images/service-3.png';

const Home = () => {


    const cardData = [
        {
          title: "Education Support for Parents and Kids",
          description:
            "Personalized sessions guide parents and students in making informed education choices and post-school plans, using data-driven insights tailored to individual goals and family context.",
          image: srcv_1, 
          className: "education-support-card",
        },
        {
          title: "Community Leadership",
          description:
            "Dedicated to creating a positive community impact through empathy and collaboration, with evidence-based programs tailored to local needs. Designed to drive meaningful change.",
          image: srcv_2,
          className: "community-leadership-card",
        },
        {
          title: "Diversity in STEM",
          description:
            "Evidence-based programs support diverse pathways to STEM careers, providing guidance to inspire students and address engagement gaps, shaping policies for Australia’s diverse communities.",
          image: srcv_3,
          className: "diversity-stem-card",
        },
      ];
    
  return (
    <div className="app-container">
    {/*
    <section className="Main-section">
    <div className="Main-overlay"></div>
    <div className="Main-head-sec">
      <h1 className="Main-title">Tailored Community Services for Lasting Impact</h1>
      <button className="Main-button">Join Our Workshops</button>
    </div>
  </section>
   */ }
    

  <MainSection
        backgroundImage={Home_bg}
        titleText="Community-driven solutions, crafted by experts"
      />

      
      <section className="cards-section">
        <div className="card card-1">
          <div className="card-icon">
          <img src={icon_1} className='icons'></img>
          </div>
          <h3 className="card-title">Building Stronger Communities</h3>
          <p className="card-description">
            Building stronger communities involves fostering collaboration, trust, and shared goals among members,
            empowering them to address local needs and challenges together.
          </p>
        </div>
        <div className="card card-2">
          <div className="card-icon">
          <img src={icon_2} className='icons'></img>
          </div>
          <h3 className="card-title">Power of Collaboration</h3>
          <p className="card-description">
          The power of collaboration lies in combining diverse skills, perspectives, and resources to achieve shared goals more efficiently. By working together, teams can solve complex problems, innovate faster, and create stronger, more sustainable solutions than individuals could on their own.
          </p>
        </div>
        <div className="card card-3">
          <div className="card-icon">
          <img src={icon_3} className='icons'></img>
          </div>
          <h3 className="card-title">Expertise</h3>
          <p className="card-description">
          Expertise is the specialized knowledge or skill gained through experience, training, or education in a particular field. It allows individuals to perform tasks efficiently and make informed decisions, driving innovation and problem-solving within their domain.          </p>
        </div>
        
      </section>
      
      <section className="border-box">
  <div className="box">
    <h4 className="head-1">What we Offer</h4>
    <button className="all-btn">All Services</button>
  </div>
  
</section>
<p className="box-description">
    SRCV Services empowers communities with tailored education support, leadership programs, and inclusive STEM pathways.
  </p>

    
    
     
      <section className="services-cards-section">
      {cardData.map((card, index) => (
        <div className={`service-card ${card.className}`} key={index}>
          <img src={card.image} alt={card.title} className="service-card-image" />
          <h3 className="service-card-title">{card.title}</h3>
          <p className="service-card-description">{card.description}</p>
        </div>
      ))}
    </section>
      <Footer></Footer>

    </div>
  );
};

export default Home;
