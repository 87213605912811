import './Footer.css';

import { Link } from 'react-router-dom';
import React from 'react';
import logo from './image.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">
        
        <div className="footer-column footer-column-logo">
          <div className="footer-logo">
            <img className="f-logo" src={logo} alt='Company Logo' />
          </div>
          <ul className="footer-list">
            <li className="footer-item">SRCV Services</li>
           </ul>
          
        </div>

        <div className="footer-column">
          <h4 className="footer-title">Quick Links</h4>
          <ul className="footer-list">
          <Link className="li-nav-link" to="/Home"><li className="footer-item">Home</li></Link>
          <Link className="li-nav-link" to="/About"> <li className="footer-item">About</li></Link>
          <Link className="li-nav-link" to="/OurTeam"><li className="footer-item">Our Team</li></Link>
          <Link className="li-nav-link" to="/Services"> <li className="footer-item">Services</li></Link>
          <Link className="li-nav-link"  to='/ContactUs'> <li className="footer-item">Contact us</li></Link>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-title">Services</h4>
          <ul className="footer-list">
          <Link className="li-nav-link" to="/Services">  <li className="footer-item">Education Support for Parents and Kids</li></Link>
          <Link className="li-nav-link" to="/Services"> <li className="footer-item">Community Leadership</li></Link>
          <Link className="li-nav-link" to="/Services"> <li className="footer-item">Diversity in STEM</li></Link>
          </ul>
        </div>

        <div className="footer-column f-1">
          <h4 className="footer-title">Social</h4>
          <ul className="footer-list">
            <li className="footer-item">LinkedIn</li>
            <li className="footer-item">Instagram</li>
            <li className="footer-item">Facebook</li>
          </ul>
        </div>

      </div>

      <div className="footer-bottom">
        <p className="footer-copyright">
          © 2024 Xstream Minds Pvt Ltd. All rights reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
