import "./Contact.css";

import Background from "./Assests/Background.jpeg";
import Feed from "./FeedbackForm";
import Footer from "../Footer/Footer";
import MainSection from "../Home/MainSection";
import React from "react";
import bg from "./Assests/bg.jpg";

const Contact = () => {
  return (
    <div>
    {/*}
      <section className="contact-section">
        <div className="contact-overlay"></div>
        <div className="contact-head-sec">
          <h1 className="contact-title">Get in Touch with SRCV Services</h1>
        </div>
      </section>
    */}

      <MainSection
      backgroundImage={bg}
      titleText="Meet the Passionate Minds Behind SRCV Services"
    />
    

      <div className="responsive-container">
        <div className="iframe-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeJ1TnQoS9IInVBATTQA7d6-vqDTs_fEBNTr2JeY7kT9PpqXQ/viewform?embedded=true"
            width="540"
            height="1179"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="iframe-content"
            className="iframe-content"
            allowFullScreen
          >
            Loading…
          </iframe>
        </div>
      </div>
      
      <Footer></Footer>
    </div>
  );
};

export default Contact;
