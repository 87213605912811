import "./Main_Nav.css";

import React, { useEffect, useState } from "react";

import {Link} from 'react-router-dom'
import logo from "./image.png";

function Main_Nav() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [contentMarginTop, setContentMarginTop] = useState(0); 

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);

    if (!isMobileMenuOpen) {
      setContentMarginTop(200); 
    } else {
      setContentMarginTop(0); 
    }
  };

  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false); 
        setContentMarginTop(0); 
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
        <Link to='/'><img src={logo} alt="logo" /></Link>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          
        <Link className="li-nav-link" to="/Home"> <li className="navbar-item">Home</li></Link>
           
        <Link className="li-nav-link" to="/About"><li className="navbar-item">About Us</li></Link>
          
        <Link className="li-nav-link" to="/Services"><li className="navbar-item">Services</li></Link>
          
        <Link className="li-nav-link" to="/OurTeam"><li className="navbar-item">Our Team</li></Link>
          
          
        </ul>

        <div className="navbar-right">
          
      <Link className="li-nav-link"  to='/ContactUs'><button className="nav-book-demo">Contact Us</button></Link>
        
          <div
            className="mobile-menu-icon"
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            ☰
          </div>
        </div>
      </nav>

      <div style={{ marginTop: contentMarginTop + 'px' }}>
       
      </div>
    </>
  );
}

export default Main_Nav;
