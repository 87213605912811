import{BrowserRouter, Route, Routes} from 'react-router-dom';

import Abou from './Components/About/Abou';
import Contact from './Components/BookDemo/Contact';
import FeedbackForm from './Components/BookDemo/FeedbackForm';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Main_Nav from './Components/Navbar/Main_Nav';
import OurTeam from './Components/OurTeam/OurTeam';
import Services from './Components/Services/Services';

function App() {
  return (
    <div className="App">


    <BrowserRouter>
     
      <Main_Nav></Main_Nav>
      <FeedbackForm></FeedbackForm>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/About' element={<Abou />} />
        <Route path='/OurTeam' element={<OurTeam></OurTeam>} />
        <Route path='/ContactUs' element={<Contact></Contact>} />
        <Route path='/Services' element={<Services />} />
    
      </Routes>

    </BrowserRouter>

    </div>
  );
}

export default App;
