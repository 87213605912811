import './Cards.css';

import React from 'react';
import card_1 from './Images/About_1.png';
import card_2 from './Images/About_2.png';
import card_3 from './Images/About_3.png';

const AboutSection = ({ title, subtitle, description, image, reverse }) => (
  <div className={`about-section ${reverse ? 'reverse' : ''}`}>
    <div className="about-content">
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
      <p>{description}</p>
      <button className="contact-button">Contact us</button>
    </div>
    <div className="about-image">
      <img src={image} alt={title} />
    </div>
  </div>
);

const Cards = () => {
  return (
    <div className="app">
      <AboutSection
        title="About Our Company"
        subtitle="About SRCV Services"
        description="Welcome to SRCV Services Community Consulting (SRCV Services), where our mission is to identify community needs, design targeted solutions, and deliver meaningful impact. We believe that community building thrives on collaboration, expertise, and a shared commitment to progress. Our goal is to empower individuals and communities through innovative programs that foster growth, resilience, and positive change to SRCV Services Community Consulting (SRCV Services), where our mission is to identify community needs, design targeted solutions, and deliver meaningful impact..."
        image={card_1} 
        reverse
      />
      <AboutSection
        title="Our Approach"
        subtitle="At SRCV Services"
        description="At SRCV Services, we understand that every community has unique needs. We work closely with members to tailor initiatives that address specific challenges. By incorporating expert insights and best practices, we ensure our programs are not only impactful but also sustainable. Through open communication and collaboration, we harness the collective skills and knowledge of our stakeholders, creating stronger, more supportive communities SRCV Services, we understand that every community has unique needs. We work closely with members to tailor initiatives that address specific challenges..."
        image={card_2} 
        
      />
      <AboutSection
        title="Why Choose Us?"
        subtitle=""
        description="SRCV Services was founded by Dr. Sohni Singh, an advocate for equality and diversity. Dr. Singh is a graduate of RMIT University and an active leader within her community. She serves on the People’s Advisory Panel for Wyndham City Council, contributing her expertise to shape the future of community resources. With a career rooted in public service, Dr. Singh is passionate about empowering communities to reach their fullest potential.SRCV Services was founded by Dr. Sohi Singh, an advocate for equality and diversity. Dr. Singh is a graduate of RMIT University and an active leader within her community..."
        image={card_3}
        reverse 
      />
    </div>
  );
};

export default Cards;
