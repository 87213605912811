import './Main.css';

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

const MainSection = ({ backgroundImage, titleText }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText(titleText.slice(0, index + 1));
      index++;
      if (index === titleText.length) clearInterval(interval);
    }, 100); 

    return () => clearInterval(interval);
  }, [titleText]);

  return (
    <section className="Main-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className="Main-overlay"></div>
    <div className="Main-head-sec">
      <h1 className="Main-title">
        {displayedText}
        <span className="cursor">|</span>
      </h1>
    </div>
    <div className="Main-button-wrapper">
    <Link className="li-nav-link"  to='/ContactUs'> <button className="Main-button">Join Our Workshops</button></Link>
    </div>
  </section>
  
  );
};

export default MainSection;
